import { useEffect, useState } from 'react'

const useMediaQuery = <T = true, U = false>(query: string, whenTrue?: T, whenFalse?: U) => {
  const mediaQuery = typeof window !== 'undefined' ? window?.matchMedia?.(query) : undefined
  const [match, setMatch] = useState(!!mediaQuery?.matches)

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery?.matches)
    mediaQuery?.addEventListener('change', handler)
    return () => mediaQuery?.removeEventListener('change', handler)
  }, [mediaQuery])

  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') {
    return whenFalse ?? false
  }

  return match ? (whenTrue ?? true) : (whenFalse ?? false)
}

export default useMediaQuery
