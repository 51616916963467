import { useState } from 'react'
import { Form, useNavigate } from '@remix-run/react'
import cx from 'classnames'
import { useRemixForm, RemixFormProvider } from 'remix-hook-form'

import { resolver } from './schema'
import { Plan } from '~/types/subscriptions'
import type { SignupFormData } from './schema'
import EmailAndTermsPanel from './EmailAndTermsPanel'
import SignUpPlanPanel from './SignUpPlanPanel'
import AccountActionPrompt from '~/modules/signup/components/AccountActionPrompt'

export type SignUpFormProps = {
  className?: string
  isMobileView?: boolean
  hideLoginLink?: boolean
  transparentPlanChooser?: boolean
  transparentConsentForm?: boolean
}

const SignUpForm = ({
  className,
  isMobileView,
  hideLoginLink,
  transparentPlanChooser = false,
  transparentConsentForm = false
}: SignUpFormProps) => {
  const navigate = useNavigate()

  const [step, setStep] = useState<number>(1)

  const methods = useRemixForm<SignupFormData>({
    mode: 'onSubmit',
    resolver,
    defaultValues: {
      plan: Plan.INDIVIDUAL_MONTHLY,
      subscriptionUserSeats: 1,
      newsletter: true
    }
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Make sure the form does not refresh the page

    // Because we're not submitting the form to the BFF server, all this does is
    // validate the form locally.
    const isValid = await methods.trigger()

    if (isValid) {
      // form data will be stored in history.state on the client
      navigate('/cub-club/join/account-details', { state: methods.getValues() })
    }
  }

  return (
    <div
      className={cx(
        {
          'rounded-2xl bg-white p-8 shadow-lg sm:min-w-[600px] dark:bg-dark':
            (step === 1 && !transparentPlanChooser) || (step === 2 && !transparentConsentForm)
        },
        className
      )}>
      <RemixFormProvider {...methods}>
        <Form method="post" onSubmit={handleSubmit}>
          <SignUpPlanPanel
            className={cx(step === 1 ? '' : 'hidden')}
            isMobileView={isMobileView}
            onComplete={() => setStep(2)}
          />
          <EmailAndTermsPanel className={cx(step === 2 ? '' : 'hidden')} onGoBack={() => setStep(1)} />
        </Form>
      </RemixFormProvider>

      {!hideLoginLink && (
        <AccountActionPrompt description="Already have an account?" actionText="Log in" actionUrl="/cub-club/login" />
      )}
    </div>
  )
}

export default SignUpForm
