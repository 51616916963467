import React from 'react'
import cx from 'classnames'

import FeatureBullets from './FeatureBullets'

interface PlansListProps {
  children: React.ReactNode
  className?: string
  commonBullets?: string[]
  name: string
}

const PlansList: React.FC<PlansListProps> = ({ className, children, commonBullets, name, ...props }) => (
  <div className="space-y-4">
    <div {...props} className={cx('radio-cards grid w-full gap-4', 'grid-cols-1', className)}>
      {children}
    </div>

    {commonBullets?.length ? (
      <div className="space-y-2">
        <h6 className="mb-0">Both plans include:</h6>
        <FeatureBullets bullets={commonBullets} />
      </div>
    ) : null}
  </div>
)

export default PlansList
