import { CheckIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'

interface FeatureBulletsProps {
  bullets: string[]
  isAccent?: boolean
  className?: string
}

const FeatureBullets: React.FC<FeatureBulletsProps> = ({ bullets, isAccent = false, className = 'text-sm mt-2' }) =>
  bullets.length ? (
    <ul className={cx('feature-bullets space-y-1', className)}>
      {bullets.map(bullet => (
        <li key={bullet} className="flex items-center space-x-1">
          <div>
            <CheckIcon className={cx('h-5 w-5', isAccent && 'text-basePink')} />
          </div>
          <span>{bullet}</span>
        </li>
      ))}
    </ul>
  ) : null

export default FeatureBullets
