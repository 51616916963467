import { useFetcher } from '@remix-run/react'
import { useDebouncedCallback } from 'use-debounce'
import { useFormContext } from 'react-hook-form'
import { useEffect, useRef } from 'react'
import type { ComponentProps, ChangeEventHandler } from 'react'

import TextInput from '~/components/forms/TextInput'
import type { CheckUserExistsResponse } from '~/routes/api.account.exists'

type TextInputProps = ComponentProps<typeof TextInput>
type UniqueEmailInputProps = Omit<TextInputProps, 'type' | 'asTextarea' | 'onChange'> & {
  onChange?: ChangeEventHandler<HTMLInputElement>
  onExistenceChange?: (exists: boolean) => void
}

const UniqueEmailInput = ({ onChange, onExistenceChange, ...textInputProps }: UniqueEmailInputProps) => {
  const formContext = useFormContext()
  const checker = useFetcher<CheckUserExistsResponse>()
  // Use a ref to track if we should consider the checker response
  const shouldCheckRef = useRef(true)

  const handleCheckEmail = useDebouncedCallback((email: string) => {
    if (email) {
      shouldCheckRef.current = true
      formContext?.trigger?.(textInputProps.name)
      checker.submit({ email }, { method: 'post', action: '/api/account/exists' })
    } else {
      // Instead of modifying checker.data directly, we'll use the ref to ignore it
      shouldCheckRef.current = false
      onExistenceChange?.(false)
    }
  }, 350)

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChange?.(event)
    handleCheckEmail(event.target.value)
  }

  const hasCheckerSubmitted = shouldCheckRef.current && !!checker.data
  const hasAccount = hasCheckerSubmitted && !!checker.data?.user

  // Call onExistenceChange when checker data updates
  useEffect(() => {
    if (hasCheckerSubmitted) {
      onExistenceChange?.(hasAccount)
    }
  }, [hasCheckerSubmitted, hasAccount, onExistenceChange])

  return (
    <TextInput
      {...textInputProps}
      type="email"
      asTextarea={false}
      onChange={handleChange as TextInputProps['onChange']}
      value={textInputProps.value}
      showValidIcon={textInputProps.showValidIcon ?? true}
      showInvalidIcon={textInputProps.showInvalidIcon ?? true}
      error={hasCheckerSubmitted && hasAccount ? 'This email is already registered' : textInputProps.error}
    />
  )
}

export default UniqueEmailInput
