import React from 'react'
import type { ReactNode } from 'react'
import cx from 'classnames'

import FeatureBullets from './FeatureBullets'

export interface PlanCardProps {
  className?: string
  classNameBullets?: string
  selected?: boolean
  isMobile?: boolean
  tagLine: string | ReactNode
  header: string | ReactNode
  bullets: string[]
  content?: string | ReactNode
  footer?: string | ReactNode
  sticker?: ReactNode
  onPlanSelect?: () => void
}

const PlanCard: React.FC<PlanCardProps> = ({
  className,
  classNameBullets,
  selected,
  tagLine,
  header,
  bullets,
  isMobile,
  content,
  footer,
  sticker,
  onPlanSelect,
  ...props
}) => {
  const handleClick = () => {
    if (!selected) {
      onPlanSelect?.()
    }
  }

  return (
    <div
      {...props}
      className={cx(
        'relative h-full cursor-pointer rounded-xl bg-white text-left text-dark outline outline-4 focus-visible:outline-offset-0 dark:bg-dark dark:text-light',
        selected ? 'outline-basePurple ' : 'opacity-80 outline-light',
        className
      )}
      onClick={handleClick}>
      {sticker}
      <div className="flex h-full flex-col p-4">
        {typeof tagLine === 'string' ? <span className="font-copy text-xs uppercase">{tagLine}</span> : tagLine}
        {typeof header === 'string' ? <span className="mt-2 text-2xl">{header}</span> : header}
        <FeatureBullets className={classNameBullets} bullets={bullets} />
        {typeof content === 'string' ? <span className="mt-2 text-2xl">{content}</span> : content}
        {typeof footer === 'string' ? <span className="mt-2 text-2xl">{footer}</span> : footer}
      </div>
    </div>
  )
}

export default PlanCard
