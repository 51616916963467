import { useState } from 'react'
import { Link } from '@remix-run/react'
import cx from 'classnames'
import { useRemixFormContext } from 'remix-hook-form'

import Button from '~/components/Button'
import UniqueEmailInput from '~/components/forms/UniqueEmailInput'
import CheckboxInput from '~/components/forms/CheckboxInput'
import { Plan } from '~/types/subscriptions'

type SignUpEmailConsentFormProps = {
  className?: string
  onGoBack: () => void
}

const EmailAndTermsPanel = ({ className, onGoBack }: SignUpEmailConsentFormProps) => {
  const {
    watch,
    formState: { isLoading, isSubmitting }
  } = useRemixFormContext()

  const plan = watch('plan')
  const [hasAccount, setHasAccount] = useState(false)
  const [hasChecked, setHasChecked] = useState(false)

  return (
    <div className={cx('relative m-auto flex max-w-[613px] flex-col items-stretch space-y-6', className)}>
      <div className="flex w-full flex-row items-start justify-center">
        <Button
          intent="semi-transparent"
          text="<"
          onClick={() => onGoBack?.()}
          isDisabled={isLoading || isSubmitting}
        />

        <div className="w-[80%] flex-grow text-center ">
          {plan === Plan.SCHOOL_ANNUAL ? (
            <>
              <h2 className="mb-2">School</h2>
              <p className="text-base font-medium leading-6">Join as a school with multiple teachers and admins</p>
            </>
          ) : (
            <>
              <h2 className="mb-2">Individual</h2>
              <p className="text-base font-medium leading-6">Join as an individual user</p>
            </>
          )}
        </div>

        <div className="w-[10%]" />
      </div>

      <div>
        <UniqueEmailInput
          name="email"
          label="Email"
          placeholder={plan === Plan.SCHOOL_ANNUAL ? `Your school's email address` : `Your personal email address`}
          autoComplete="email"
          required
          hint={
            plan === Plan.SCHOOL_ANNUAL && !hasAccount
              ? 'This email address should belong to a business manager, administrator or someone with financial authority at your school.'
              : undefined
          }
          onExistenceChange={exists => {
            // Only update if we have an actual check result
            setHasAccount(exists)
            setHasChecked(true)
          }}
        />
      </div>

      {hasAccount ? (
        <Button
          to="/cub-club/login"
          intent="info"
          text="Account exists already. Log in?"
          size="lg"
          isDisabled={isLoading || isSubmitting}
        />
      ) : (
        <>
          <CheckboxInput label="Send me the latest news from Pevan & Sarah" name="newsletter" />

          <CheckboxInput
            label={
              <>
                I have read and agree to the Cub Club{' '}
                <Link to="/terms" className="font-bold text-baseBlue">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/privacy" className="font-bold text-baseBlue">
                  Privacy Policy
                </Link>{' '}
              </>
            }
            name="termsAgreement"
          />

          <CheckboxInput
            label="I acknowledge that the subscriptions will automatically renew until cancelled"
            name="autoRenewalAcknowledgement"
          />

          <Button
            intent="success"
            size="lg"
            text={plan === Plan.SCHOOL_ANNUAL ? 'Continue signup' : 'Start 7 day free trial'}
            className="flex-1"
            isLoading={isLoading || isSubmitting}
            isDisabled={isLoading || isSubmitting || !hasChecked || hasAccount}
          />
        </>
      )}
    </div>
  )
}

export default EmailAndTermsPanel
