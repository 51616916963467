import cx from 'classnames'

import type { Plan } from '~/types/subscriptions'
import { SubscriptionType } from '~/types/subscriptions'

interface PriceInfoProps {
  price: number
  type: SubscriptionType
  plan?: Plan
  isMobile?: boolean
}

const PriceInfo = ({ isMobile, price, type, plan }: PriceInfoProps) => {
  const [wholeNumber, decimal] = price.toString().split('.')
  const frequency = plan ? (plan.match(/monthly/) ? 'month' : 'year') : 'month'

  return (
    <div className={cx(isMobile ? 'mb-[29.5px]' : 'h-[135.5px]', ' text-center')}>
      <p className="text-2xl font-medium leading-8">
        <span className="mr-1 align-top">$</span>
        <span className={cx('font-bold', isMobile ? 'text-4xl leading-10' : 'text-7xl leading-none')}>
          {wholeNumber}
        </span>
        {decimal && (
          <span className={cx('text-base font-medium', isMobile ? 'leading-5' : 'leading-6')}>.{decimal}</span>
        )}
        <span className="pl-2 align-super">AUD</span>
      </p>
      {type === SubscriptionType.INDIVIDUAL && (
        <p className={cx('text-base font-medium', isMobile ? 'pt-[1.5px] leading-5' : 'pt-2 leading-6')}>
          Per {frequency}
        </p>
      )}
      {type === SubscriptionType.SCHOOL && (
        <>
          <p className={cx('text-base font-medium', isMobile ? 'pt-[1.5px] leading-5' : 'pt-2 leading-6')}>
            Per user per year
          </p>
          <p className="pt-2 text-xs font-normal leading-4">minimum 2 users</p>
        </>
      )}
    </div>
  )
}

export default PriceInfo
